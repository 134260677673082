<template>
  <div class="last-five-games">
    <div class="last-five-games__header">
      <hr class="separator" />
      <div class="last-five-games__header__title">
        Últimos 5 Partidos
      </div>
    </div>
    <div class="last-five-games__period">
      <RadioGamePeriod :section="'ultimos 5 partidos'" />
    </div>
    <div class="last-five-games__playing-as">
      <RadioPlayingAs :is-mobile="playingAsMobileFlag" :section="'ultimos 5 partidos'" />
    </div>
    <div class="last-five-games__tables" :class="{ 'is-goal': isGoals }">
      <div class="last-five-games__tables__team">
        <TableFiveGames
          :team-data="teamsData.homeTeam"
          :first-column-width="columnWidth"
          :is-mobile="fiveGamesTableMobileFlag"
          :isGoals="isGoals"
        />
      </div>
      <div class="last-five-games__tables__team">
        <TableFiveGames
          :team-data="teamsData.awayTeam"
          :first-column-width="columnWidth"
          :is-mobile="fiveGamesTableMobileFlag"
          :isGoals="isGoals"
          is-away-team
        />
      </div>
    </div>
    <LegendLastSeason :mobile="isMobile" :type="2" />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LastFiveGames',
  components: {
    LegendLastSeason: () => import('@/components/Sections/GolTipster-v2/General/LegendLastSeason'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
    RadioGamePeriod: () => import('@/components/Sections/GolTipster-v2/General/RadioGamePeriod'),
    TableFiveGames: () => import('@/components/Sections/GolTipster-v2/General/TableFiveGames'),
  },
  props: {
    teamsData: {
      type: Object,
      default: () => ({}),
    },
    isGoals: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fiveGamesTableMobileFlag: false,
      playingAsMobileFlag: false,
    };
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        this.fiveGamesTableMobileFlag = this.isGoals ? newValue < 940 : newValue < 686;
        this.playingAsMobileFlag = newValue < 464;
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    columnWidth() {
      return this.isGoals ? (this.displayWidth < 453 ? '5rem' : '30%') : '7.5rem';
    },
    isMobile() {
      return this.displayWidth < 464;
    },
  },
};
</script>

<style scoped lang="scss">
.last-five-games {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &__title {
      z-index: 1;
      background-color: white;
      padding: 0 0.6rem;
      font-family: Roboto-Black, sans-serif;
      font-size: 1.625rem;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.85;
      letter-spacing: -0.26px;
      width: fit-content;
      color: #132839;
    }
  }

  &__period {
    margin-top: 2rem;
  }

  &__playing-as {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__tables {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 0.5rem;
    margin-top: 2.75rem;

    &__team {
      width: 100%;
      max-width: 24.5rem;

      &:last-child {
        justify-self: flex-end;
      }
    }

    &.is-goal {
      & .last-five-games__tables__team {
        width: 100%;
        max-width: 29rem !important;

        &:last-child {
          justify-self: flex-end;
        }
      }

      @media screen and (max-width: 940px) {
        grid-template-columns: 1fr;
        column-gap: 0;
        margin-top: 1.75rem;

        & .last-five-games__tables__team {
          max-width: 100% !important;

          &:last-child {
            justify-self: flex-start;
            margin-top: 1.875rem;
          }
        }
      }
    }

    @media screen and (max-width: 686px) {
      grid-template-columns: 1fr;
      column-gap: 0;
      margin-top: 1.75rem;

      &__team {
        max-width: 100%;

        &:last-child {
          justify-self: flex-start;
          margin-top: 1.875rem;
        }
      }
    }
  }
}

.separator {
  border-top: dashed 1px #94a8ba;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
}
</style>
